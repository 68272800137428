import { apiUrl } from "../util/urls";

export default async (email) => {
  try {
    const res = await fetch(
      `${apiUrl}/sendResetEmail.php?email=${encodeURIComponent(email)}`
    );
    const data = await res.json();
    return data;
  } catch (err) {
    return err;
  }
};
