import * as React from "react";

import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import ForgotPassword from "../components/AuthComponents/ForgotPassword/ForgotPassword";

const ForgotPasswordPage = () => {
  return (
    <Layout vertical={true}>
      <Seo title="Forgot Password" />
      <ForgotPassword />
    </Layout>
  );
};

export default ForgotPasswordPage;
