import React from "react";
import {
  DefaultInput,
  MDTModal,
  ModalContent,
  ModalTitle,
  ModalWrapper,
} from "../../../styles/StyledComponents";
import Button from "../../Button/Button";
import styled from "styled-components";
import { useState } from "react";
import { useWarning } from "../../../util/hooks";
import sendResetEmail from "../../../mdtApi/sendResetEmail";

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 67%;
    padding-left: 30px;
  }
`;

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState<string>("");
  const [warning, createWarning] = useWarning();
  const [content, setContent] = useState(
    "Enter your email to send a reset verification email."
  );
  const [hideForm, setHideForm] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    (async () => {
      let res = await sendResetEmail(email);
      if (!res.success) {
        createWarning(res.reason);
      } else {
        setContent("Success! Check your email for the reset link.");
        setHideForm(true);
      }
    })();
  };

  return (
    <>
      <div>{content}</div>
      {!hideForm && (
        <>
          <DefaultInput
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder={"Email"}
          />
          <Button onClick={handleSubmit} style={{ alignSelf: "flex-start" }}>
            Reset Password
          </Button>
        </>
      )}
    </>
  );
};

const ForgotPassword = () => {
  return (
    <ModalWrapper>
      <MDTModal>
        <ModalTitle>Reset Password</ModalTitle>
        <ModalContent>
          <ForgotPasswordContainer>
            <ForgotPasswordForm />
          </ForgotPasswordContainer>
        </ModalContent>
      </MDTModal>
    </ModalWrapper>
  );
};

export default ForgotPassword;
